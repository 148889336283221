<template>
  <canvas :key="canvasKey" ref="chart" />
</template>

<script>
import Chart from 'chart.js/auto'
import 'chartjs-plugin-dragdata'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { deepSpread } from 'core-ui/utils/deepSpread'
import _cloneDeep from 'lodash.clonedeep'
import { shallowRef } from 'vue'

const darkModeOptions = {
  scales: {
    x: {
      ticks: {
        color: '#ffffff'
      },
      grid: {
        color: '#96abb4',
        borderColor: '#96abb4',
        tickColor: '#96abb4'
      }
    },
    y: {
      ticks: {
        color: '#ffffff'
      },
      grid: {
        color: '#96abb4',
        borderColor: '#96abb4',
        tickColor: '#96abb4'
      }
    }
  },
  plugins: {
    title: {
      color: '#ffffff'
    },
    legend: {
      title: {
        color: '#ffffff'
      },
      labels: {
        color: '#ffffff'
      }
    }
  }
}

const darkModeCanvas = {
  id: 'custom_canvas_background_color',
  beforeDraw: (chart) => {
    const { ctx } = chart
    ctx.save()
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = '#252f34'
    ctx.fillRect(0, 0, chart.width, chart.height)
    ctx.restore()
  }
}

export default {
  name: 'RenderChart',
  props: ['options', 'updateChart', 'chartData', 'chartType', 'darkMode'],
  setup () {
    /*
     * GT - May 21, 2024: removing this as it seemed to cause issues with destroying charts.
     * Doing a `watch` on `this.chart` and console.logging it seems to alternate between a `Chart` and
     * a DOM element, which was confusing, but would prevent `this.chart.destroy()` from working on old charts.
     * We might want to revisit this later on.
     */
    // const chart = shallowRef(null)
    // return {
    //   chart
    // }
  },
  data () {
    return {
      canvasKey: 0
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  },
  beforeUnmount () {
    if (this.chart) {
      try {
        this.chart.destroy()
      } catch (e) {}
    }
  },
  methods: {
    renderChart (data, chartOptions) {
      if (!this.$refs.chart) {
        return
      }

      const options = _cloneDeep(chartOptions)
      const pluginLibraries = []

      if (options.plugins?.datalabels) {
        pluginLibraries.push(ChartDataLabels)
      }

      if ((this.chartType === 'pie' || this.chartType === 'doughnut') && options.plugins?.datalabels) {
        options.plugins = {
          ...options.plugins,
          datalabels: {
            formatter: (value, ctx) => {
              const datasets = ctx.chart.data.datasets
              let retVal = 0
              if (datasets.indexOf(ctx.dataset) === datasets.length - 1) {
                const sum = datasets[0].data.reduce((a, b) => a + b, 0)
                retVal = sum > 0 ? Math.round((value / sum) * 100) + '%' : null
              } else {
                retVal = value
              }
              return (retVal === '0%') ? null : retVal
            },
            color: '#fff',
            ...options.plugins?.datalabels
          }
        }
        pluginLibraries.push(ChartDataLabels)
      }

      if (this.chartType === 'bubble' && options.plugins?.datalabels) {
        options.plugins = {
          ...options.plugins,
          datalabels: {
            anchor: function (context) {
              return 'end'
            },
            align: function (context) {
              return 'end'
            },
            color: function (context) {
              return 'black'
            },
            font: {
              weight: 'bold'
            },
            formatter: function (value) {
              return value.label
            },
            offset: 2,
            padding: 0
          }
        }
        pluginLibraries.push(ChartDataLabels)
      }

      let enhancedOptions = options
      if (this.darkMode) {
        pluginLibraries.push(darkModeCanvas)
        enhancedOptions = deepSpread(options, darkModeOptions)
      }

      const draw = () => {
        this.chart = new Chart(this.$refs.chart, {
          plugins: pluginLibraries,
          type: this.chartType,
          data,
          options: enhancedOptions
        })
      }
      if (this.chart) {
        try {
          this.chart.destroy()
        } catch (e) {}
        this.canvasKey++
        this.$nextTick(() => {
          draw()
        })
      } else {
        draw()
      }
    }
  },
  watch: {
    updateChart () {
      this.renderChart(this.chartData, this.options)
    }
  }
}
</script>
